import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import GridLineButton from 'components/GridLineButton'
import ImgCopyBox from 'components/ImgCopyBox'
import { strings } from 'js/strings'

export default function IndustriesTemplate({ data }) {
  const page = data.wpPage

  return (
    <Layout
      bodyClassName='page-template page-template-page-builder page-builder page page-id-132 industries app-data index-data singular-data page-data page-132-data page-industries-data page-builder-data'
      page={page}
    >
      <GridLineButton
        content={page.template.industries.grid}
        buttonText={strings.readMore}
        isCompact={true}
      />
      <ImgCopyBox content={page.template.industries.imageCopy} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...RelatedBlockPage
      ...ContactBlockPage
      template {
        templateName
        ... on WpTemplate_Industries {
          industries {
            grid {
              title
              icon {
                ...GatsbyImage
              }
              pageLink {
                ...PageLinkPageInner
                ...PageLinkPostInner
              }
            }
            imageCopy {
              title
              body
              pageLink {
                ...PageLinkPageInner
                ...PageLinkPostInner
              }
              pageLinkText
            }
          }
        }
      }
    }
  }
`
