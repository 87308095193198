import React from 'react'
import PageLink from 'components/PageLink'
import { strings } from '../js/strings'

export default function ImgCopyBox({ content }) {
  const { title, body, pageLink } = content
  return (
    <section className='img-copy hypercubes '>
      <div className='img-copy__wrap '>
        <div className='hypercubes__base hypercubes__base-left'></div>
        <div className='hypercubes__base hypercubes__base-right'></div>

        <div className='img-copy__img'>
          <img src='' alt='' className='img-copy__image' />
        </div>

        <div className='img-copy__copy'>
          <div className='img-copy__copy-wrap'>
            <h2 className='img-copy__copy__title'>{title}</h2>

            <div
              className='img-copy__copy__excerpt'
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </div>
        </div>
        {pageLink && (
          <PageLink pageLink={pageLink} className='img-copy__minimal'>
            {strings.contact}
          </PageLink>
        )}
      </div>
    </section>
  )
}
